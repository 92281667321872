import React, { useEffect } from "react";
import Layout from "../../components/layoutAr";
import Seo from "../../components/seoAr.js";

const NotFoundPage = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/ar")) {
      document.querySelector("body").classList.add("rightToLeft");
    }
  }, []);
  return (
    <Layout>
      <Seo title="404: Not found" lang="ar" />
      <h1>404 غير موجود</h1>
      <p>لقد اخترت طريق لا يؤدي لأي غاية.. خيبة!</p>
    </Layout>
  );
};

export default NotFoundPage;
